import cookie from 'cookie'
import React from 'react'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import EventsPageComponent from '../../components/events'
import Head from '../../components/head'
import { getEventSliderData, getEvents } from '../../http_service'
import './style.scss'

const EventsPage = ({ serverData }) => {
  return (
    <>
      <Head title="Events Page" />
      <EventsPageComponent serverData={serverData} />
    </>
  )
}

export async function getServerData(props) {
  const cookies = props.headers.get('cookie')
  let storedPassword = ''
  if (cookies) {
    storedPassword = cookie.parse(cookies)?.['ttf-access'] || ''
  }
  try {
    const [events, eventsSliderData] = await Promise.all([getEvents(), getEventSliderData()])

    return {
      props: { events, eventsSliderData, storedPassword, host: props.headers.get('host') },
    }
  } catch (error) {
    return {
      props: { error: true, message: error },
    }
  }
}

export default EventsPage
